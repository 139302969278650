<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Certificates</h2>
        <div  v-if="certificates.length > 0">
            <v-card v-for="training in certificates" v-bind:key="training.id" outlined elevation="2" class="mt-2 pa-3">
                <h3 style="color: #002060;">{{ training.category.category }} 
                    <v-btn v-if="training.status == 'active'" color="primary" style="float: right;text-transform: capitalize;" small class="success">Valid</v-btn> 
                    <v-btn v-else style="float: right;text-transform: capitalize;" small class="red">Expired</v-btn> 
                </h3>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Status: </strong>{{ training.version }}</p>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Date Certified: </strong>{{ training.date_certified }}</p>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Expiry date: </strong>{{ training.expiry_date }}</p>
                <a v-if="training.document" target="blank"  color="primary" :href="training.document"
                style="text-transform: capitalize;" small class="my-anchor-tag mt-1">View Certificate</a> 
            </v-card>
        </div>
        <div v-else>
            <p style="color: #002060; text-align: center; margin-top: 20px; padding: 10px; margin-bottom:0px !important;">No Certificate Found</p>
        </div>

    </v-container>
</template>

<script>

export default {
    name:'PassportAuthorization',
    data: () => ({
        user_position: "",
        user_id: "",
        user:{},
        certificates:[],
    }),
    mounted () {
        this.user = JSON.parse(localStorage.getItem('user'))
        if(this.user == null){
            this.$router.push('/')
        }
        this.certificates = JSON.parse(localStorage.getItem('certificates'))

    }

}
</script>

<style scoped>
.my-anchor-tag {
  display: inline-block;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #002060; /* Replace with the background color of v-btn */
}
</style>